import React from "react"

import DocumentLayout from "../../components/layout/document"

export default () => {
  return (
    <DocumentLayout title="Signature Mechanism">
      <div className="headline">
        <h1 className="text-primary m-0">Signature Mechanism</h1>
      </div>

      <p>
        Request signature is an option to ensure integrity of the request, it
        need to be agreed during enrollment. Once set, it is mandatory for every
        single request to the Liquid Platform.
      </p>
      <p>
        For sign_type as SHA512, Output should be hashed with SHA512 with salt
        (a secret string to be provided during enrollment), to be part of the
        request for facilitate integrity of messages transferred between the two
        parties.
      </p>
      <p>
        All parameters within the message are converted into set of name-values
        pairs and shall be concatenated with the “&” character.
      </p>
      <p>The value shall be URL-encoded before concatenated.</p>
      <p>
        The string shall include the sign code (salt, provided by Liquid
        Platform during enrollment) at end of the string, concatenated with the
        “&” character.
      </p>
    </DocumentLayout>
  )
}
